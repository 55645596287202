<template>
  <div>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          @click:clear="$emit('getDate', null)"
          v-model="fullDate"
          :label="$t(label)"
          append-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          outlined
          clearable
        ></v-text-field>
      </template>
      <v-date-picker
        v-if="!picker"
        @change="picker = true"
        :locale="$i18n.locale"
        v-model="date"
        no-title
        scrollable
      >
      </v-date-picker>
      <v-time-picker
        v-if="picker"
        v-model="time"
        use-seconds
        format="24hr"
      ></v-time-picker>
      <v-spacer></v-spacer>
      <v-card v-if="picker">
        <v-btn text color="primary" @click="picker = false">
          {{ $t("back") }}
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="pickFullDate"
          :disabled="time == null || date == null"
        >
          {{ $t("agree") }}
        </v-btn>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: ["label"],
  data() {
    return {
      menu1: false,
      date: null,
      time: null,
      picker: false,
      fullDate: null,
    };
  },
  methods: {
    pickFullDate() {
      this.fullDate = this.date + " " + this.time;
      this.menu1 = false;
      this.$emit("getDate", this.fullDate);
    },
  },
};
</script>
