<template>
  <v-row
    class="justify-center align-center"
    @keyup.enter="$emit('getData', data)"
    v-exit-keyup="clearData"
  >
    <v-col cols="12" sm="6" md="4" lg="2">
      <v-text-field
        v-model="data.name"
        :label="$t('serach-by-name')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.edu_class_id = $event"
        :label="'edu class'"
        :Store="eduClass"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
      ></auto-complete>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.subject_id = $event"
        :label="'subject'"
        :Store="Subject"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
      ></auto-complete>
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="2">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="data.from_starting_date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.starting_date"
            :label="$t('starting date')"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            clearable
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.starting_date"
          no-title
          scrollable
          @change="
            () => {
              $refs.menu1.save(data.starting_date);
              menu1 = false;
            }
          "
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="data.finishing_date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.finishing_date"
            :label="$t('finishing date')"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            clearable
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.finishing_date"
          no-title
          scrollable
          @change="
            () => {
              $refs.menu2.save(data.finishing_date);
              menu2 = false;
            }
          "
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="mt-n8" lg="2">
      <v-icon
        @click="$emit('getData', data)"
        color="primary"
        class="ma-2"
        x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
import { Subject } from "@/store/teacher/subjects";
import { eduClass } from "@/store/teacher/eduClass/index";
export default {
  directives: {
    "exit-keyup": {
      bind(el, binding, vnode) {
        el.addEventListener("keyup", (event) => {
          if (event.key === "Escape") {
            binding.value(event);
          }
        });
      },
    },
  },
  props: ["ageItems", "individual", "types"],
  data() {
    return {
      Subject,
      eduClass,
      data: {},
      menu1: false,
      menu2: false,
      clearAutocomplete: false,
    };
  },
  methods: {
    clearData() {
      this.data = {};
      this.clearAutocomplete = true;
      setTimeout(() => {
        this.clearAutocomplete = false;
      }, 500);
      this.$emit("getData", this.data);
    },
  },
};
</script>
