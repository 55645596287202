import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";

export default class agegroup {
  endPoint = "teacher/ageGroup";

  tableData = {
    data: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      name: {
        name: "name",
        keys: ["name"],
      },
      description: {
        name: "description",
        keys: ["description"],
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  postState = new State();
  editState = new State();
  deleteState = new State();
  get(params) {
    let request = new Request({
      method: "get",
      endpoint: `${this.endPoint}`,
      params: params,
      token: this.token,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }

  async post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `${this.endPoint}/add`,
      data: payload,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async edit(payload, id, { onSuccess }) {
    let request = new Request({
      method: "put",
      endpoint: `${this.endPoint}/${id}`,
      data: payload,
    });

    await this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(i18n.t("edit success"), { timeout: 4000, rtl: true }, "sucess");
          if (onSuccess) onSuccess();
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async delete(id) {
    let request = new Request({
      method: "delete",
      endpoint: `${this.endPoint}/${id}`,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
}

export const ageGroup = new agegroup();
