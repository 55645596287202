<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add exam") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container class="d-block">
          <v-row class="justify-center">
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.duration"
                type="number"
                outlined
                :label="$t('duration')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6"
              ><date-time-picker
                v-if="dialog"
                @getDate="data.starting_date = $event"
                :label="'starting date'"
              ></date-time-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6"
              ><date-time-picker
                v-if="dialog"
                @getDate="data.finishing_date = $event"
                :label="'finishing date'"
              ></date-time-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.grade_to_pass"
                type="number"
                outlined
                :label="$t('grade to pass')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <auto-complete
                v-if="dialog"
                @getItem="data.subject_id = $event"
                :data="type == 'edit' ? editData.subject : null"
                :label="'subject'"
                :Store="Subject"
                :isObject="true"
                :clearAutocomplete="clearAuto"
              ></auto-complete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <auto-complete
                v-if="dialog"
                @getItem="data.edu_class_id = $event"
                :data="type == 'edit' ? editData.edu_class : null"
                :label="'edu class'"
                :Store="eduClass"
                :isObject="true"
                :clearAutocomplete="clearAuto"
              ></auto-complete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <auto-complete
                v-if="dialog"
                @getItem="data.skilles = $event"
                :data="type == 'edit' ? editData.skilles : null"
                :label="'skilles'"
                :Store="Skilles"
                :isObject="true"
                :clearAutocomplete="clearAuto"
                multiple
              ></auto-complete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <auto-complete
                v-if="dialog"
                @getItem="data.age_groups = $event"
                :data="type == 'edit' ? editData.age_groups : null"
                :label="'age-groups'"
                :Store="ageGroup"
                :isObject="true"
                :clearAutocomplete="clearAuto"
                multiple
              ></auto-complete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-select
                :label="$t('individual differences')"
                :items="individualDifferencesItems"
                item-text="title"
                item-value="value"
                v-model="data.individual_differences"
                multiple
                outlined
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-select
                :label="$t('types')"
                :items="types"
                item-text="text"
                item-value="value"
                v-model="data.types"
                multiple
                outlined
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="disable"
          :loading="createLoader"
          color="blue darken-1"
          text
          @click="create"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Exams as Store } from "@/store/teacher/exams";
import { Subject } from "@/store/teacher/subjects";
import { eduClass } from "@/store/teacher/eduClass/index";
import { Exercise } from "@/store/exercise/add";
import { ageGroup } from "@/store/teacher/ageGroup";
import { Skilles } from "@/store/teacher/skilles";

import dateTimePicker from "./dateTimePicker.vue";
export default {
  components: {
    dateTimePicker,
  },
  props: ["dialog", "editData", "type"],
  data() {
    return {
      Store,
      Exercise,
      Subject,
      eduClass,
      ageGroup,
      Skilles,
      data: {
        name: "",
        duration: "",
        grade_to_pass: "",
        finishing_date: "",
        starting_date: "",
        subject_id: "",
        edu_class_id: "",
      },
      clearAuto: false,
      page: 1,
      menu1: false,
      menu2: false,
      confirmedPassword: null,
      numberOfKeys: 7,
    };
  },
  methods: {
    create() {
      if (this.type == "create") {
        this.$emit("create", this.data);
        this.data = {};
        this.clearAuto = true;
      } else {
        for (let key in this.data) {
          if (this.data[key] == this.editData[key]) {
            delete this.data[key];
          }
        }
        this.$emit("edit", this.data);
      }
    },
    edit() {
      console.log("you are in edit");
    },
    close() {
      this.data = {
        finishing_date: "",
        starting_date: "",
        subject_id: "",
        edu_class_id: "",
      };
      this.$emit("close");
    },
  },
  computed: {
    createLoader() {
      return Store.postState.loading;
    },

    disable() {
      return (
        this.data.name == "" ||
        this.data.duration == "" ||
        this.data.grade_to_pass == "" ||
        this.data.finishing_date == "" ||
        this.data.starting_date == "" ||
        this.data.subject_id == "" ||
        this.data.edu_class_id == ""
      );
    },
    individualDifferencesItems() {
      return [
        { title: this.$t("easy"), value: "easy" },
        { title: this.$t("medium"), value: "medium" },
        { title: this.$t("hard"), value: "hard" },
      ];
    },
    types() {
      return [
        { value: "voice_record", text: this.$t("voice_record") },
        { value: "choose", text: this.$t("choose") },
        { value: "match", text: this.$t("match") },
        { value: "true_or_false", text: this.$t("true_or_false") },
        { value: "reorder", text: this.$t("reorder") },
        { value: "categorize", text: this.$t("categorize") },
        { value: "fill_gaps", text: this.$t("fill_gaps") },
      ];
    },
  },
  created() {
    Subject.get({ page: 1 });
    eduClass.get({ page: 1 });
  },
  watch: {
    editData(val) {
      this.data = { ...val };
    },
  },
};
</script>
