<template>
  <div>
    <create
      @create="create($event)"
      @close="createDialog = false"
      :dialog="createDialog"
      :type="'create'"
    />

    <delete-popup
      @deleteData="deleteItem($event)"
      @closePopup="deleteDialog = false"
      :dialog="deleteDialog"
      :item="deleteData"
      :loader="deleteLaoder"
    />
    <exams-filter @getData="getData($event)" />
    <v-row class="mb-2">
      <v-col class="d-flex justify-end"
        ><v-btn @click="createDialog = true" color="primary"
          ><span class="white--text">{{ $t("add exam") }}</span></v-btn
        ></v-col
      >
    </v-row>
    <data-table
      @updatePage="getData({ page: $event })"
      :headers="headers"
      :data="data"
      :meta="meta"
      :loader="loader"
      :deleteable="deleteAble"
      :showable="showAble"
    >
      <template #header:status>
        <span>{{ $t("status") }}</span>
      </template>
      <template v-slot:status="{ item }">
        <div v-if="item.status == 'finished'">
          <v-icon class="mx-1" color="red">mdi-lock-outline</v-icon>
          <span>{{ $t("finished") }}</span>
        </div>
        <div v-else-if="item.status == 'active'">
          <v-icon class="mx-1" color="primary">mdi-lock-open-outline</v-icon>
          <span>{{ $t("active") }}</span>
        </div>
        <div v-else-if="item.status == 'upcoming'">
          <v-icon class="mx-1" color="brown">mdi-timer-sand</v-icon>
          <span>{{ $t("waiting") }}</span>
        </div>
      </template>
    </data-table>
  </div>
</template>
<script>
import create from "./create.vue";
import examsFilter from "./filter.vue";
import { Exams as Store } from "@/store/teacher/exams";
export default {
  components: {
    create,
    examsFilter,
  },
  data() {
    return {
      Store,
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      editData: null,
      deleteData: null,
      currentPage: 1,
      filterData: {},
    };
  },
  methods: {
    getData(data) {
      if (Object.keys(data).length == 0) this.filterData = {};
      Object.assign(this.filterData, data);
      this.currentPage = this.filterData.page;
      Store.get(this.filterData);
    },
    async create(data) {
      await Store.post(data);
      this.createDialog = false;
      this.getData({ page: 1 });
    },
    async edit(data) {
      await Store.edit(data, this.editData.id);
      this.editDialog = false;
      this.getData({ page: this.currentPage });
    },
    editAble(obj) {
      this.editData = obj;
      this.editDialog = true;
    },
    deleteAble(obj) {
      this.deleteData = obj;
      this.deleteDialog = true;
    },

    showAble(obj) {
      // Exams.tableData.showData = obj;
      this.$router.push(`/exam/${obj.id}`);
    },

    async deleteItem(id) {
      await Store.deleteItem(id);
      this.deleteDialog = false;
      this.getData({ page: this.currentPage });
    },
  },
  computed: {
    headers() {
      return Store.tableData.headers;
    },
    data() {
      return Store.tableData.data;
    },
    loader() {
      return Store.getState.loading;
    },
    deleteLaoder() {
      return Store.deleteState.loading;
    },
    meta() {
      return Store.tableData.meta;
    },
  },
  created() {
    this.getData({ page: 1 });
  },
};
</script>
